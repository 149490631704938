<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      :row-class-name="tableRowClassName"
      :span-method="arraySpanMethod"
    >
      <el-table-column align="center" prop="cangku_name" label="所属仓库" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="cailiao_name" label="材料名称" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige_name" label="材料规格" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="amount" label="回库数量" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="date" label="回库时间" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="remark" label="回库备注" min-width="20%"> </el-table-column>
      <el-table-column align="center" label="现场照片" min-width="10%">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="!(scope.row.type === '2' || scope.row.type === '3')" @click="handlePhotos(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="added" label="添加" min-width="10%"> </el-table-column>
      <el-table-column prop="wuzi_confirm" label="是否审核" width="100">
        <template slot-scope="scope" v-if="!(scope.row.type === '2' || scope.row.type === '3')">
          <el-tag :type="scope.row.wuzi_confirm === false ? 'primary' : 'success'">{{ scope.row.wuzi_confirm === false ? '未审核' : '已审核' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="管理" min-width="10%">
        <template slot-scope="scope" v-if="!(scope.row.type === '2' || scope.row.type === '3')">
          <el-button size="mini" @click="handleEdit(scope.row)" v-if="userType.kufanghuiku_edit">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
          <el-button size="mini" type="primary" :disabled="scope.row.wuzi_confirm" @click="handleWuziConfirm(scope.row)" v-if="userType.kufanghuiku_wuziconfirm"
            >物资审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'
export default {
  data() {
    return {}
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.kufanghuiku_del) {
          if (this.userType.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    //编辑
    handleEdit(row) {
      this.$emit('edit', row)
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },
    //物资审核
    handleWuziConfirm(row) {
      this.$emit('wuziConfirm', row)
    },

    //显示图片
    handlePhotos(row) {
      this.$emit('showPhotos', row)
    },
    //设置小计和合计行的状态
    tableRowClassName({ row, rowIndex }) {
      // console.log(row)
      // console.log(rowIndex)
      if (row.type === '2') {
        return 'xiaoji-row'
      } else if (row.type === '3') {
        return 'heji-row'
      } else if (rowIndex % 2 === 0) {
        return 'stripe-row'
      }
      return 'normal-row'
    },
    //小计和合计列合并
    arraySpanMethod({ row, columnIndex }) {
      // console.log(row)
      if (row.type === '2' || row.type === '3') {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 3]
        } else if (columnIndex === 2) {
          return [0, 0]
        }
      }
    }
    //合计
    // getSum(param) {
    //   const { columns, data } = param
    //   const sums = []
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = '合计'
    //       return
    //     }
    //     const values = data.map(item => Number(item[column.property]))
    //     // column.property === 'bmoney'  bmoney 是你要总计table中的那一列的 prop值
    //     if (column.property === 'amount') {
    //       sums[index] = values.reduce((prev, curr) => {
    //         const value = Number(curr)
    //         if (!isNaN(value)) {
    //           return prev + curr
    //         } else {
    //           return prev
    //         }
    //       }, 0)
    //       // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
    //       // sums[index] += sums[index];sums[index];
    //     } else {
    //       sums[index] = '--'
    //     }
    //   })
    //   return sums
    // }
  }
}
</script>
